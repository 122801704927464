<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Don't want to see this
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="app-bold-font font-20">
        Tell us why you don't want to see this
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column mt-3">
        <v-radio-group v-model="reason" @change="onChange">
          <v-radio :value="option" v-for="option in options" :key="option">
            <template v-slot:label>
              <div
                class="d-flex hover-item pa-2 w-100 rounded-lg flex-column justify-center"
                :class="{
                  'selected-item-color': reason == option,
                }"
              >
                <div class=" font-18 mb-2">
                  {{ option }}
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions class="pb-6 mt-3 d-flex justify-end" v-if="reason">
        <v-btn
          color="primary"
          @click="onSubmit"
          class="white--text"
          width="150"
          :loading="loading"
          :disabled="!reason"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: String,
    _id: String,
    onClose: {
      type: Function,
    },
    onBlocked: Function,
  },
  data() {
    return {
      message: null,
      loading: false,
      category: null,
      reason: null,
      options: [
        "I'm not interested in the author",
        "I'm not interested in this topic",
        "I've seen too many posts on this topic",
        "I've seen this post before",
        "This post is old",
        "It's something else",
      ],
    };
  },
  methods: {
    ...mapActions("event", {
      blockEvent: "block",
    }),
    onChange() {
      this.message = null;
      console.log(this.reason);
    },
    onSubmit() {
      this.loading = true;

      this.blockEvent({
        _id: this._id,
        reason: this.reason,
      })
        .then(() => {
          this.loading = false;
          this.onBlocked();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
  }
}
.selected {
  background: #eef3f8;
}
</style>
